import React from 'react';
import cx from 'classnames';
import CategoryIcon from 'components/CategoryIcon';
import styles from './style.scss';

const ShipmentStatus = ({
  shipmentData,
}) => {
  const shipmentTitle = shipmentData.shipment_state.state_label
    && shipmentData.shipment_state.state_label
      .replace(/_/g, ' ')
      .toLowerCase();
  const category = shipmentData.status ? shipmentData.status.toLowerCase() : '';

  return (
    <div className={styles.shipmentStatusGrid}>
      <div className={cx(styles.shipmentStatus)}>
        <div className={styles.shipmentIcon}>
          <CategoryIcon category={category} isActive className={styles.tick} />
        </div>
        <div className={styles.shipmentHeaderText}>
          <h3 className={cx(styles.shipmentTitle, styles[category])}>
            {shipmentTitle}
          </h3>
        </div>
        <div className={styles.shipmentBodyText}>
          <p className={cx(styles.shipmentCarrier, 'detail2')}>
            Shipment: {shipmentData.id}<br />
            Carrier: {shipmentData.carrier}<br />
            Service: {shipmentData.carrier_service}
          </p>
          <p className={cx(styles.shipmentBodyId, 'detail2')}>Tracking reference/s:</p>
          {shipmentData.tracking_references.length && shipmentData.tracking_references.map(
            ref => (shipmentData.carrier_tracking_page
              ? <a href={shipmentData.carrier_tracking_page} target="_blank" rel="noreferrer" key={ref} className={cx(styles.shipmentBodyId, 'detail2')}>{ref}</a>
              : <p key={ref} className={cx(styles.shipmentBodyId, 'detail2')}>{ref}</p>),
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipmentStatus;
