import React from 'react';
import FieldActionButton from '../FieldActionButton/FieldActionButton';

const AuthenticateButton = ({
  authenticateUrl,
  carrierIntegrationName,
  queryParameters = {},
}) => {
  const buildUrlWithParameters = (url, parameters) => {
    const paramsArray = Object.values(parameters);
    const dynamicPath = paramsArray.length > 0 ? `/${paramsArray.join('/')}` : '';
    return `${url}${dynamicPath}`;
  };

  const isDisabled = !authenticateUrl;
  const urlWithParameters = buildUrlWithParameters(authenticateUrl, queryParameters);

  return (
    <div>
      <FieldActionButton
        onClick={() => { if (authenticateUrl) window.location.href = urlWithParameters; }}
        tooltip={isDisabled ? 'No Authentication URL set' : `Authenticate with ${carrierIntegrationName}`}
        buttonText="Authenticate"
        disabled={isDisabled}
      >
        Authenticate
      </FieldActionButton>
    </div>
  );
};

export default AuthenticateButton;
