import React from 'react';
import cx from 'classnames';
import styles from './FieldActionButton.scss';

const FieldActionButton = ({
  tooltip, onClick, className, buttonText,
}) => (
  <button
    className={cx(styles.fieldActionButton, className)}
    onClick={onClick}
    data-tip={tooltip}
    type="button"
  >
    { buttonText }
  </button>
);

export default FieldActionButton;
